
@import "@/styles/app.scss";

.title-div-small {
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (min-width: $vw-tablet) {
    width: 200%;
  }
}

.text-div {
  margin-top: 1rem;
}

.dilemma-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;

  @media (min-width: $vw-tablet) {
    width: 400px;
  }

  @media (min-width: $vw-desktop) {
    width: 400px;
  }

  .text-div-questionstep {
    @extend .text-div-smallsquashed;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    span {
      color: $colorWhite
    }
  }

  //Everything with .questionstep-input-slider has to do with top slider
  .questionstep-input-slider {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    width: 100%;
    outline: none;
    /*  slider progress trick  */
    overflow: hidden;
    border-radius: 16px;
  }

  /* Track: webkit browsers */
  .questionstep-input-slider::-webkit-slider-runnable-track {
    height: 15px;
    background: $colorWhite;
    border-radius: 16px;
  }

  /* Track: Mozilla Firefox */
  .questionstep-input-slider::-moz-range-track {
    height: 15px;
    background: $colorWhite;
    border-radius: 16px;
  }

  /* Thumb: webkit */
  .questionstep-input-slider::-webkit-slider-thumb {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    height: 15px;
    width: 15px;
    background-color: $colorPink;
    border-radius: 50%;
    border: 2px solid $colorPink;
    /*  slider progress trick  */
    box-shadow: -407px 0 0 400px $colorPink;
  }

  /* Thumb: Firefox */
  .questionstep-input-slider::-moz-range-thumb {
    height: 15px;
    width: 15px;
    background-color: $colorPink;
    border-radius: 50%;
    border: 1px solid $colorPink;
    /*  slider progress trick  */
    box-shadow: -407px 0 0 400px $colorPink;
  }

  .loading-div {
    @extend .text-div;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 200px;
    margin-top: 100px;
    margin-bottom: 10px;
    color: $colorGreen;

    .loading-gif {
      width: 100px;
      height: 100px;
    }
  }

  .comparison-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;

    .archetype-div {
      @extend .text-div-small;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      width: 150px;
      font-size: 28px;
      line-height: 30px;
      margin: 5px;

      @media (min-width: $vw-desktop) {
        width: 200px;
        font-size: 34px;
        line-height: 40px;
      }

      &:first-child {
        margin-right: 0.5rem;
      }

      img {
        border-radius: 10px;
        max-width: 40vw;
      }

      .archetype-img {
        border: 2px solid $colorBlack;
        background: $colorBlack;
        padding-top: 5px;
      }

      .dilemmaname-span {
        width: 100%;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
        text-align: left;
        color: $colorWhite;
      }
    }

    .other-div {
      @extend .title-div-small;
      text-align: center;
      position: absolute;
      border-radius: 5px;
      background-color: $colorBlack;
      color: $colorWhite;
      width: 3.5rem;
      height: 3.5rem;
      line-height: 3.5rem;
      z-index: 1;
      margin-top: 7.5rem;

      @media (min-width: $vw-desktop) {
        width: 4rem;
        height: 4rem;
        line-height: 4rem;
        margin-top: 10rem;
      }
    }
  }

  .optionbutton-div {
    @extend .text-tiny;
    display: flex;
    flex-direction: row;

    .optionbutton-span {
      font-size: 12px;
      font-weight: 500;

      @media (min-width: $vw-tablet) {
        font-size: 15px;
      }

      @media (min-width: $vw-desktop) {
        font-size: 15px;
      }
    }

    .button-dilemma-option {
      background-color: $colorWhite;
      border: 1px solid $colorPink;
      color: $colorBlack;
      cursor: pointer;
      width: 20%;
      height: 4rem;

      &:hover {
        background-color: $colorPink;
      }
    }

    .option-button-selected {
      background-color: $colorGreen;
      border: 1px solid $colorGreen;
      color: $colorBlack;
      cursor: pointer;
      width: 20%;
      height: 4rem;
    }
  }

  .notification-div {
    @extend .body-small-info;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
  }

  .button-div {
    display: flex;
    flex-direction: row;
    width: 100%;

    @media (min-width: $vw-tablet) {
      margin-top: 4rem;
      margin-bottom: 8rem;
    }

    .button-wrapper--left {
      margin-right: 0.5%;
      width: 49%;

      .previous-button {
        @extend .button-white--left;
        height: 3rem;
        padding: 1rem;
        min-width: 100%;
      }
    }

    .button-wrapper--right {
      margin-left: 0.5%;
      width: 49%;

      .next-button {
        @extend .button-white--right;
        height: 3rem;
        padding: 1rem;
        min-width: 100%;
      }
    }
  }
}
