@charset "UTF-8";
@import "@/styles/global/variables.scss";

@font-face {
  font-family: 'Sohne';
  src: url('~@/fonts/Sohne-Halbfett.otf');
  font-weight: normal;
  font-style: normal
}

@font-face {
  font-family: 'Sohne';
  src: url('~@/fonts/Sohne-Fett.otf');
  font-weight: bold;
  font-style: normal
}

.futura-font {
  font-family: 'Sohne', Arial, Helvetica, sans-serif;
}

.newake-font {
  font-family: 'Sohne', Arial, Helvetica, sans-serif;
}

.title-div-big {
  @extend .heading1;
  margin: 20px 0;
}

.title-div-medium {
  @extend .heading2;
  margin: 20px 0;
}

.title-div-small {
  @extend .heading3;
  margin: 20px 0;
}

.title-em-pink {
  font-style: normal;
  color: $colorPink;
}

.title-em-black {
  font-style: normal;
  color: $colorBlack;
}

.text-div {
  @extend .body;
  margin-bottom: 2.5rem;
}

.text-div-small {
  @extend .body-small;
  margin-bottom: 2.5rem;
}

.text-div-smallsquashed {
  @extend .body-smallsquashed;
  margin-bottom: 2.5rem;
}

.text-tiny {
  @extend .body-tiny;
}

.remove-margin {
  margin: 0;
}

.text-em-pink {
  font-style: normal;
  color: $colorPink;
}

.text-em-black {
  font-style: normal;
  font-weight: bold;
  color: $colorBlack;
}

.button-span {
  @extend .futura-font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;

  @media (min-width: $vw-desktop) {
    font-size: 20px;
    line-height: 32px;
  }
}

.info-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $colorPink;
  margin-top: 0.5rem;
  padding: 10px 15px;
  min-height: 60%;
  border-radius: 25px;

  .alert-img {
    width: 25px;
    height: 25px;
    padding-right: 5px;
  }
}

.heading1 {
  @extend .newake-font;
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 64px;
  color: $colorWhite;

  @media (min-width: $vw-desktop) {
    font-size: 128px;
    line-height: 136px;
  }
}

.heading2 {
  @extend .newake-font;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 48px;
  color: $colorWhite;

  @media (min-width: $vw-desktop) {
    font-size: 80px;
    line-height: 90px;
  }
}

.heading3 {
  @extend .newake-font;
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
  font-weight: 400;
  color: $colorWhite;

  @media (min-width: $vw-desktop) {
    font-size: 48px;
    line-height: 56px;
  }
}

.body {
  @extend .futura-font;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: $colorBlack;

  @media (min-width: $vw-desktop) {
    font-size: 24px;
    line-height: 32px;
  }
}

.body-small {
  @extend .futura-font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: $colorBlack;

  @media (min-width: $vw-desktop) {
    font-size: 20px;
    line-height: 32px;
  }
}

.body-smallsquashed {
  @extend .futura-font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $colorBlack;

  @media (min-width: $vw-desktop) {
    font-size: 20px;
    line-height: 24px;
  }
}

.body-small-info {
  @extend .futura-font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $colorBlack;

  @media (min-width: $vw-desktop) {
    font-size: 12px;
    line-height: 16px;
  }
}

.body-tiny {
  @extend .futura-font;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
}

.table-title {
  @extend .futura-font;
  font-style: normal;
  font-weight: 450;
  font-size: 42px;
  line-height: 32px;
}

.table-count {
  @extend .futura-font;
  font-style: normal;
  font-weight: 450;
  font-size: 26px;
  line-height: 56px;
}

.table-header {
  @extend .futura-font;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 56px;
}

.table-row {
  @extend .futura-font;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 56px;
}

.modal-title {
  @extend .futura-font;
  font-style: normal;
  font-weight: 450;
  font-size: 42px;
  line-height: 32px;
  margin-bottom: 16px;
}

.modal-description {
  @extend .futura-font;
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 32px;
  margin-bottom: 36px;
}

.modal-inputlabel-font {
  @extend .futura-font;
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 32px;
}

.modal-input-font {
  @extend .futura-font;
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 32px;
}

.modal-errormessage-font {
  @extend .futura-font;
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 32px;
  color: $colorRed;
}

.modal-createbutton-font {
  @extend .futura-font;
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 32px;
}

.modal-cancelbutton-font {
  @extend .futura-font;
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 32px;
  color: $colorAntGray;
  text-decoration: underline;
}

.popup-span-font {
  @extend .futura-font;
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 32px;
  color: $colorWhite;
}
