
@import "@/styles/app.scss";

.navbar {
  background-color: $colorBlack;
  height: 100%;

  .navbartop-div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 107px;
  }

  .navbar-ul {
    list-style-type: none;
    margin: 0 16px;
    padding: 0;

    .navbar-li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      margin: 0;
      padding: 0;

      &:hover {
        background-color: $colorHoverGray;
        color: $colorWhite;
      }
    }

    .navbar-span {
      @extend .newake-font;
      display: block;
      color: $colorSilverGray;
      text-decoration: none;
      font-style: normal;
      font-weight: 450;
      font-size: 22px;
      line-height: 32px;
      padding: 18px;
    }

    .navbar-li-selected {
      @extend .navbar-li;
      background-color: $colorHoverGray;
    }

    .navbar-span-selected {
      @extend .navbar-span;
      color: $colorWhite;
    }

    .navbar-icon {
      width: 25px;
      height: 25px;
      margin-left: 10px;
    }
  }

  .bottom {
    position: absolute;
    bottom: 30px;
    left: 40px;

    .logout-div {
      position: relative;
      cursor: pointer;

      span {
        color: $colorWhite;
        font-style: normal;
        font-weight: 450;
        font-size: 22px;
        line-height: 56px;
      }

      img {
        position: absolute;
        left: 90px;
        bottom: 10px;
      }
    }
  }
}

