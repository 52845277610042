@charset "UTF-8";
@use "sass:math";

@import "@/styles/global/buttons.scss";
@import "@/styles/global/forms.scss";
@import "@/styles/global/typography.scss";
@import "@/styles/global/variables.scss";

html, body {
    min-height: 100%;
    min-width: 100%;
    width: 100%;
    margin: 0;
    background-color: $colorLightGray;
}

:root {
    --zoom: 1;
}
