
@import '@/styles/app.scss';

.row-div {
  display: flex;
  flex-direction: row;
}

.title-div-small {
  margin: 20px;
}

.main-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  word-break: break-word;

  @media (min-width: $vw-tablet) {
    width: 60vw;
  }

  @media (min-width: $vw-desktop) {
    width: 40vw;
  }

  .main-content-div {
    align-items: center;
    margin: 20px;

    .content-div {
      background-color: $colorPink;
      align-items: center;
      margin-top: 4rem;
      padding: 25px;
      border-radius: 25px;

      .text-div-small {
        margin-bottom: 1.5rem;
      }
    }
  }

  .logo-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    @media (max-width: $vw-tablet) {
      padding-top: 3rem;
      padding-bottom: 6rem;
    }
  }
}

.logo {
  width: 100px;
  height: auto;
  margin: 10px;

  @media (min-width: $vw-tablet) {
    position: absolute;
    right: 25px;
    bottom: 25px;
    width: 250px;
  }

  @media (min-width: $vw-desktop) {
    position: absolute;
    right: 50px;
    bottom: 50px;
    width: 250px;
  }
}
