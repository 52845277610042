
@import '@/styles/app.scss';

.main-div {

  .modal-button-open {
    @extend .button-gray;
    width: 208px;
    height: 64px;
    margin: 24px 48px 19px 0;
    float: right;
  }

  .modal-div {
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .modalcontent-div {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      box-shadow: 0 0 0 50vmax rgba(0, 0, 0, .5);
      max-height: 81%;
      z-index: 1;
      background-color: $colorWhite;
      margin: auto;
      padding: 60px 75px;
      width: 550px;

      .modal-errormessage {
        @extend .modal-errormessage-font;
      }

      .button-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .cancel-button {
          @extend .modalcancel-button;
          @extend .modal-cancelbutton-font;
          height: 32px;
          width: 200px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
}
