
@import "@/styles/app.scss";

.text-div-smallsquashed {
  margin-top: 2rem;
  margin-bottom: 0;
}

.content-div {
  background-color: $colorPink;
  margin: 3rem 0;
  padding: 25px 15px;
  border-radius: 25px;

  .text-div-small-nomargin {
    @extend .text-div-small;
    margin-bottom: 0;
  }

  .smallnote-div {
    font-size: 14px;
    color: $colorWhite;
    margin-bottom: 1.5rem;
  }

  .flexcolumn-div {
    display: flex;
    flex-direction: column;
  }

  .button-div {
    display: flex;
    justify-content: center;
    width: 100%;

    .next-button {
      @extend .button-white--right;
      padding: 1rem;
      height: 3rem;
    }
  }

  .rewardemail-input {
    height: 2.5rem;
    width: 93%;
    background-color: $colorPink;
    border: 1px solid $colorWhite;
    border-radius: 5px;
    padding-left: 1rem;
    color: $colorWhite;
    font-size: 16px;
    line-height: 32px;
    font-weight: 500;

    &:focus {
      outline: none;
    }
  }

  .privacy-checkbox {
    accent-color: $colorBlack;
  }
}
