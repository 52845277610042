
@import "@/styles/app.scss";

.main-div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.login-div {
  min-width: 400px;
  min-height: 300px;

  .logincontent-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-color: $colorBlack;
    border: 1px solid $colorWhite;
    border-radius: 10px;
    padding: 30px;

    .login-input {
      background-color: $colorBlack;
      border: 1px solid $colorWhite;
      border-radius: 5px;
      color: $colorWhite;
      width: 388px;
      height: 51px;
      margin-top: 1rem;
      padding: 5px 20px;

      &:focus {
        outline: none;
        border: 1px solid $colorPink;
      }
    }

    .text-div {
      margin: 10px;
    }

    .login-button {
      @extend .button-white;
      width: 426px;
      height: 75px;
      padding: 5px 20px;
      margin-top: 1rem;
    }

    .errormessage-span {
      color: $colorPink;
      margin: 10px;
    }
  }
}
