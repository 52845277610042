
@import '@/styles/app.scss';

.screenOutDiv {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  background-color: $colorBrokenWhite;
  box-shadow: 3px 3px 5px -2px rgba(0, 0, 0, 0.11);
  margin-bottom: 32px;
  padding: 31px 40px;

  .screenouttitle-div {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    justify-content: space-between;
    margin-bottom: 8px;

    .removequestion-button {
      border: none;
      background: none;

      &:hover {
        cursor: pointer;
      }

      .trashbin-img {
        width: 22px;
        height: 28px;
      }
    }
  }

  .formrow-div {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    justify-content: space-between;
  }

  .dropdownoptions-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .dropdownitem-checkbox {
      min-height: 26px;
      min-width: 26px;
      margin: 10px;
      accent-color: $colorBlack;

      &:hover {
        accent-color: $colorHoverGray;
      }
    }

    .dropdownitem-textinput {
      @extend .modal-input-font;
      background-color: $colorBrokenWhite;
      color: $colorBlack;
      height: 60px;
      width: 95%;
      border: none;
      padding-left: 24px;
      border-bottom: 1px solid $colorBlack;
      margin-bottom: 16px;
    }

    .dropdownminus-button {
      background-color: $colorMistyGray;
      color: $colorAntGray;
      border: 1px solid $colorAntGray;
      border-radius: 5px;
      min-height: 26px;
      min-width: 26px;
      margin: 10px;

      &:hover {
        background-color: $colorSilverGray;
        cursor: pointer;
      }
    }
  }

  .adddropdownitem-button {
    display: flex;
    align-content: flex-start;
    align-items: center;
    background-color: $colorBrokenWhite;
    box-shadow: 3px 3px 5px -2px rgba(0, 0, 0, 0.11);
    border: none;
    min-height: 68px;
    width: 100%;
    padding-left: 26px;

    &:hover {
      background-color: $colorMistyGray;
      cursor: pointer;
    }

    .adddropdownitem-span {
      margin-left: 20px;
    }
  }
}

