
@import '@/styles/app.scss';

.main-div {

  .modal-button-open {
    @extend .button-gray;
    width: 208px;
    height: 64px;
    margin: 24px 48px 19px 0;
    float: right;
  }

  .modal-div {
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .modalcontent-div {
      display: flex;
      align-items: center;
      flex-direction: column;
      overflow-y: auto;
      max-height: 81%;
      z-index: 1;
      background-color: $colorLightGray;
      margin: auto;
      padding: 30px;
      border: 2px solid $colorDarkGray;
      border-radius: 10px;
      width: 500px;
    }
  }
}
