
@import "@/styles/app.scss";

#app {
  display: flex;
  flex-direction: row;
  background-image: url('~@/images/background/bg_mobiel.png');
  justify-content: center;
  overflow: auto;
  overflow-x: hidden;


  @media (min-width: $vw-tablet) {
    background-image: url('~@/images/background/bg_desktop.png');
  }

  @media (min-width: $vw-desktop) {
    background-image: url('~@/images/background/bg_desktop.png');
  }
}


#app-admin {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#app-admin-login {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-image: url('~@/images/background/bg_desktop.png');

}

.leftSideBar {
  width: 15vw;
  min-width: 200px;
  height: 100vh;
}

.rightSide {
  display: flex;
  flex-direction: column;
  width: 85vw;
  max-height: 100vh;
  overflow-y: scroll;
}
