
@import "@/styles/app.scss";

.content-div {
  background-color: $colorPink;
  margin-top: 4rem;
  padding: 25px;
  min-height: 60%;
  border-radius: 25px;

  .text-div-small {
    margin-bottom: 1.5rem;
  }

  .button-div {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;

    @media (min-width: $vw-tablet) {
      margin-top: 4rem;
      margin-bottom: 8rem;
    }

    .next-button {
      @extend .button-white--right;
      padding: 1rem;
      height: 3rem;
    }
  }
}
