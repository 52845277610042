
@import "@/styles/app.scss";

.content-div {
  align-content: center;

  .videotext-div {
    background-color: $colorPink;
    margin-top: 2rem;
    padding: 25px;
    min-height: 60%;
    border-radius: 25px;
    margin-bottom: 1rem;

    .text-div-small {
      margin-bottom: 0;
    }
  }

  .text-div-smallsquashed {
    justify-content: center;
    display: flex;

    .info-div {
      @extend .body-small-info;
      margin-top: 2rem;
      margin-bottom: 0.5rem;
    }
  }


  .button-div {
    display: flex;
    justify-content: center;
    width: 100%;

    @media (min-width: $vw-tablet) {
      margin-top: 4rem;
      margin-bottom: 8rem;
    }

    .next-button {
      @extend .button-white--right;
      padding: 1rem;
      height: 3rem;
    }
  }

  .introductionvideo-div {
    position: relative;
    padding-bottom: 56.25%;
    margin-bottom: 2rem;
    border: none;
    border-radius: 0.5rem;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }

  .introductionvideo-div iframe, .introductionvideo-div object, .introductionvideo-div embed {
    position: absolute;
    border: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

