
@import "@/styles/app.scss";

.topbar-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: $colorWhite;
  min-height: 107px;

  h1 {
    width: 80%;
    margin-left: 3rem;
    color: $colorBlack;
  }

  .topbarbuttons-div {
    width: 20%;

    button {
      @extend .button-black;
      width: 208px;
      height: 64px;
      margin: 24px 48px 19px 0;
      float: right;
    }
  }
}

.companycontent-div {
  display: flex;
  flex-direction: column;
  margin: 20px;


  .companycollection-div {
    display: flex;
    flex-direction: column;
    background-color: $colorWhite;
    padding: 72px 48px 48px 48px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .tabletitle-span {
      @extend .table-title;
      color: $colorBlack;
      margin-bottom: 16px;
    }

    .tablecount-span {
      @extend .table-count;
    }

    .company-table {
      border-collapse: collapse;
      background-color: $colorWhite;
      width: 100%;
      margin-top: 16px;

      th {
        @extend .table-header;
        border-top: 1px solid $colorMistyGray;
        border-bottom: 1px solid $colorMistyGray;
        color: $colorSilverGray;
        text-align: left;
        padding: 6px;
      }

      td {
        @extend .table-row;
        text-align: left;
        padding: 6px;
        word-break: break-word;

        .checkbox-div {
          display: flex;
          justify-content: center;
          align-items: center;

          .tablecheckbox-input {
            height: 30px;
            width: 30px;
            border: 1px solid $colorSilverGray;
            border-radius: 5px;
            accent-color: $colorBlack;
            margin: 10px;
          }
        }

        .clickable-span {
          text-decoration: underline;
          cursor: pointer;

          .copyicon-img {
            height: 22px;
            width: 19px;
          }
        }

        .intable-button {
          @extend .button-intable;
          padding: 16px;
        }
      }
    }
  }
}
