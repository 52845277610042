
@import '@/styles/app.scss';

.main-div {

  .modal-button-open {
    @extend .button-gray;
    width: 208px;
    height: 64px;
    margin: 24px 48px 19px 0;
    float: right;
  }

  .modal-div {
    position: fixed;
    z-index: 1;
    padding-top: 50px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .modalcontent-div {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      box-shadow: 0 0 0 50vmax rgba(0, 0, 0, .5);
      max-height: 80%;
      z-index: 1;
      background-color: $colorWhite;
      margin: auto;
      padding: 60px 75px;
      width: 1100px;

      .formrow-div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-height: 545px;
        width: 100%;

        .formcolumn-div {
          display: flex;
          flex-direction: column;
          padding: 20px;
          width: 50%;
          overflow-y: auto;

          &:first-child {
            border-right: 1px solid $colorAntGray;
          }

          .checkbox-div {
            display: flex;
            flex-direction: row;

            .rewardactivecheckbox-input {
              @extend .button-gray;
              width: 32px;
              height: 32px;
              accent-color: $colorBlack;

              &:hover {
                accent-color: $colorHoverGray;
              }
            }

            .rewardactivecheckbox-label {
              @extend .modal-inputlabel-font;
              margin-left: 10px;
            }
          }

          .addrespondentquestion-button {
            display: flex;
            align-content: flex-start;
            align-items: center;
            background-color: $colorBrokenWhite;
            box-shadow: 3px 3px 5px -2px rgba(0, 0, 0, 0.11);
            border: none;
            min-height: 68px;
            width: 100%;
            padding-left: 26px;

            &:hover {
              background-color: $colorMistyGray;
              cursor: pointer;
            }

            .addrespondentquestion-span {
              margin-left: 20px;
            }
          }
        }
      }


      .button-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .cancel-button {
          @extend .modalcancel-button;
          @extend .modal-cancelbutton-font;
          height: 32px;
          width: 200px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
}
