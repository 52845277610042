@charset "UTF-8";
@import "@/styles/global/variables.scss";


.button-black {
  @extend .body-small;
  margin: 0;
  background-color: $colorBlack;
  border: none;
  border-radius: 0;
  color: $colorWhite;

  &:hover {
    background-color: $colorHoverGray;
    cursor: pointer;
  }
}

.button-gray {
  @extend .body-small;
  margin: 0;
  border-radius: 0;
  background-color: $colorDustyGray;
  border: none;

  &:hover {
    background-color: $colorHoverGray;
    cursor: pointer;
  }
}

.button-wrapper--right {
  display: flex;
  position: relative;
  justify-content: space-between;
  background-color: $colorBlack;
  align-items: center;
  cursor: pointer;

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 12px solid $colorBlack;
    border-top: 24px solid transparent;
    border-bottom: 24px solid transparent;
    position: absolute;
    top: 0;
    left: 100%;
  }
}

.button-white--right {
  @extend .newake-font;
  display: flex;
  position: relative;
  justify-content: center;
  background-color: $colorBlack;
  border: 2px solid $colorGreen;
  border-right: none;
  color: $colorGreen;
  align-items: center;
  font-style: normal;
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  cursor: pointer;

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 12px solid $colorGreen;
    border-top: 24px solid transparent;
    border-bottom: 24px solid transparent;
    position: absolute;
    left: 100%;
    margin-left: 12px;
  }

  &:before {
    content: '';
    width: 12px;
    height: 48px;
    background-color: $colorGreen;
    position: absolute;
    left: 100%;

    @media only screen and (max-device-width: 1024px) {
      .button-white--right {
        &:before {
          width: 10px;
          margin-left: 2px;
        }
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  .arrow-right {
    display: block;
    margin-left: 0.5rem;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid black;
  }
}

.button-wrapper--left {
  display: flex;
  position: relative;
  justify-content: space-between;
  background-color: $colorBlack;
  border: 0;
  align-items: center;
  cursor: pointer;

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-right: 12px solid $colorBlack;
    border-top: 24px solid transparent;
    border-bottom: 24px solid transparent;
    position: absolute;
    top: 0;
    right: 100%;
  }
}

.button-white--left {
  @extend .newake-font;
  display: flex;
  position: relative;
  justify-content: center;
  background-color: $colorBlack;
  border: 2px solid $colorGreen;
  border-left: none;
  color: $colorGreen;
  align-items: center;
  font-style: normal;
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  cursor: pointer;

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-right: 12px solid $colorGreen;
    border-top: 24px solid transparent;
    border-bottom: 24px solid transparent;
    position: absolute;
    right: 100%;
    margin-right: 12px;
  }

  &:before {
    content: '';
    width: 12px;
    height: 48px;
    background-color: $colorGreen;
    position: absolute;
    right: 100%;

    &:disabled {
      cursor: not-allowed;
    }

    .arrow-left {
      display: block;
      left: 2.5rem;
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-right: 5px solid black;
    }
  }

  .button-white {
    @extend .newake-font;
    display: flex;
    position: relative;
    justify-content: center;
    background-color: $colorWhite;
    border: 0;
    color: $colorBlack;
    align-items: center;
    font-style: normal;
    font-size: 16px;
    line-height: 32px;
    font-weight: 600;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }

    .arrow-right {
      display: block;
      margin-left: 0.5rem;
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid black;
    }

    .arrow-left {
      display: block;
      left: 2.5rem;
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-right: 5px solid black;
    }
  }

  .button-intable {
    @extend .newake-font;
    background-color: $colorMistyGray;
    border: none;
    border-radius: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: $colorBlack;

    &:hover {
      background-color: $colorSilverGray;
      cursor: pointer;
    }
  }

  .modalcreate-button {
    margin: 0 0 8px 0;
    background-color: $colorBlack;
    border: none;
    border-radius: 0;
    color: $colorWhite;

    &:hover {
      background-color: $colorHoverGray;
      cursor: pointer;
    }
  }

  .modalcancel-button {
    @extend .modal-cancelbutton-font;
    background-color: $colorWhite;
    width: 200px;
    height: 32px;
    border: none;
  }
}
