
@import "@/styles/app.scss";

.screenout-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .text-div-smallsquashed {
    margin-bottom: 0;

    .info-div {
      margin-top: 1rem;
      background: transparent;
      color: $colorWhite;
    }
  }

  .content-div {
    background-color: $colorPink;
    padding: 25px 15px;
    margin-bottom: 2rem;
    min-height: 60%;
    border-radius: 25px;

    .text-div-smallsquashed {
      margin-bottom: 0;
    }

    .screenoutquestion-div {
      margin-top: 1rem;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .button-div {
    display: flex;
    justify-content: flex-end;
    width: 90%;

    @media (min-width: $vw-tablet) {
      margin-top: 4rem;
      margin-bottom: 8rem;
    }

    .next-button {
      @extend .button-white--right;
      padding: 1rem;
      height: 3rem;
    }
  }
}

