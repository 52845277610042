@charset "UTF-8";
@import "@/styles/global/variables.scss";
@import "@/styles/global/buttons.scss";

.formkit-form {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}

.formkit-actions {
  width: 100%;
}

.formkit-outer {
  width: 100%;
}

.formkit-wrapper {
  width: 100%;
}

.formkit-inner {
  width: 100%;

  select {
    width: 100%;
  }
}

[data-invalid] .formkit-input {
  border-color: red;
}

.formkit-input {
  @extend .modal-input-font;
  background-color: $colorBrokenWhite;
  color: $colorBlack;
  height: 60px;
  width: 95%;
  border: none;
  padding-left: 24px;
  border-bottom: 1px solid $colorBlack;
  margin-bottom: 36px;

  &[type='submit'] {
    @extend .button-gray;
    height: 64px;
    width: 100%;
    margin: 20px 0;
    text-align: center;
  }

  &[type='number'] {
    width: 90%;
    padding-left: 10px;
  }
}

.formkit-label {
  @extend .modal-inputlabel-font;
  margin-bottom: 8px;
}
