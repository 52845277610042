
@import '@/styles/app.scss';

.row-div {
  display: flex;
  flex-direction: row;
}

.title-div-small {
  margin: 20px;
}

.main-div-transparant {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  word-break: break-word;

  @media (min-width: $vw-tablet) {
    width: 60vw;
  }

  @media (min-width: $vw-desktop) {
    width: 40vw;
  }
}

.main-div-black {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $colorBlack;
  width: 100vw;
  height: 100vh;
  word-break: break-word;

  @media (min-width: $vw-tablet) {
    width: 40vw;
  }

  @media (min-width: $vw-desktop) {
    width: 40vw;
  }
}

.content-div {
  align-items: center;
  margin: 20px;
}

.logo-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  @media (max-width: $vw-tablet) {
    padding-top: 3rem;
    padding-bottom: 6rem;
  }
}

.logo {
  width: 100px;
  height: auto;
  margin: 10px;

  @media (min-width: $vw-tablet) {
    position: absolute;
    right: 25px;
    bottom: 25px;
    width: 250px;
  }

  @media (min-width: $vw-desktop) {
    position: absolute;
    right: 50px;
    bottom: 50px;
    width: 250px;
  }
}
