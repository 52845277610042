
@import "@/styles/app.scss";

//To remove arrows from the number input on chrome
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

//To remove arrows from the number input on firefox
input[type=number] {
  -moz-appearance: textfield;
}

.screenout-div {
  display: flex;
  flex-direction: column;

  .yesnobuttons-div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%
  }

  .yesno-select {
    height: 2.5rem;
    width: 100%;
    background-color: $colorPink;
    border: 1px solid $colorWhite;
    border-radius: 10px;
    padding-left: 1rem;
    color: $colorWhite;
    font-size: 16px;
    line-height: 32px;
    font-weight: 500;

    &:focus {
      outline: none;
    }
  }

  .numericrange-input {
    height: 2.5rem;
    width: 93%;
    background-color: $colorPink;
    border: 1px solid $colorWhite;
    border-radius: 10px;
    padding-left: 1rem;
    color: $colorWhite;
    font-size: 16px;
    line-height: 32px;
    font-weight: 500;

    &:focus {
      outline: none;
    }
  }

  .dropdown-select {
    height: 2.5rem;
    width: 100%;
    background-color: $colorPink;
    border: 1px solid $colorWhite;
    border-radius: 10px;
    padding-left: 1rem;
    color: $colorWhite;
    font-size: 16px;
    line-height: 32px;
    font-weight: 500;

    &:focus {
      outline: none;
    }
  }
}
