@charset "UTF-8";

// ===============================================================
// Variables
// ===============================================================

// Colors
$colors: (
        primary: #F87E0B,
        secondary: #5C23F7,
        red: #CC392B,
        green: #55B747,
        light-green: #E1F8E2,
        blue: #5C23F7,
        mid-blue: #7E52F4,
        light-blue: #DCE2F9,
        dark-orange: #F19749,
        orange: #F87E0B,
        mid-orange: #F8E2C6,
        light-orange: #FCF7F2,
        yellow: #EFD875,
        dark-gray: #333333,
        gray: #808080,
        mid-gray: #EBEBEB,
        light-gray: #F5F5F5,
        white: #ffffff,
        black: #000000,
        matt-black: #191919,
        border: #000000,

);


// Colors
$colorMattBlack:      #191919;
$colorBlue:           #5DAFCF;
$colorLightBlue:      #D8EEF5;
$colorOrange:         #FF6300;
$colorGreen:          #06E68F;
$colorGray:           #F7F7F7;
$colorLightGray:      #f6f6f4;
$colorBackgroundGray: #1B1B1B;
$colorDarkGray:       #333333;
$colorLabelGray:      #494949;
$colorDustyGray:      #979797;
$colorLightBlack:     #202020;
$colorMediumGray:     #8b8b8b;
$colorRed:            #eb0000;
$colorDefault:        $colorRed;
$colorDefaultLight:   lighten($colorRed, 10);
$colorLightPurple:    #EFECF8;
$colorBorder:         #EBEBEB;
$colorPlaceholder:    #E7E7E7;
$colorBlack:          #151515;
$colorPink:           #DCABC0;
$colorBeige:          #EDE9DC;
$colorWhite:          #FFFFFF;
$colorBrokenWhite:    #F8F8F8;
$colorHoverGray:      #393939;
$colorMistyGray:      #E1E1E1;
$colorSilverGray:     #AAAAAA;
$colorAntGray:        #6B6B6B;

// VW sizing var
$vw-mobile:   375px;
$vw-tablet:   768px;
$vw-desktop:  1820px;
