
@import "@/styles/app.scss";

.content-div {

  .text-div-smallsquashed {
    background: $colorPink;
    padding: 10px 20px;
    border-radius: 25px;
  }

  .comparison-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .explanation-img {
      pointer-events: none; //To prevent search-icon thing on edge that popped up

      @media (min-width: $vw-tablet) {
        width: 50%;
      }

      @media (min-width: $vw-desktop) {
        width: 50%;
      }
    }
  }

  .areyouready-div {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
  }

  .button-div {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 1px;

    @media (min-width: $vw-tablet) {
      margin-top: 4rem;
      margin-bottom: 8rem;
    }

    .next-button {
      @extend .button-white--right;
      padding: 1rem;
      height: 3rem;
    }
  }

  .explanation-img {
    width: 100%;
  }
}
